<template>
    <div>
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6">
                        <h1>Submitted Ideas</h1>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <el-tooltip content="refresh" placement="top">
                            <span class="clickable"
                                ><i
                                    class="fas fa-redo"
                                    :class="$apollo.loading ? 'fa-spin' : ''"
                                    @click="
                                        $apollo.queries.get_hackathon_approval_ideas.skip = false;
                                        $apollo.queries.get_hackathon_approval_ideas.refetch();
                                    "
                                ></i
                            ></span>
                        </el-tooltip>
                    </b-col>
                </b-row>

                <div v-if="$apollo.loading && table_data.length == 0">
                    <b-row
                        class="mt-2 d-flex align-items-center justify-content-center"
                    >
                        <b-col
                            class="d-flex align-items-center justify-content-center"
                        >
                            <Transition>
                                <h1>
                                    <i class="fas fa-spinner fa-spin ml-1"></i>
                                </h1>
                            </Transition>
                        </b-col>
                    </b-row>
                </div>
                <b-row v-else>
                    <b-col>
                        <el-table
                            class="table-responsive align-items-center table-flush no-lines"
                            header-row-class-name="thead-light"
                            row-class-name="table-rows"
                            :data="table_data"
                            :empty-text="'No ideas have been submitted.'"
                        >
                            <el-table-column
                                label="Name"
                                min-width="150px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <p class="mb-0">
                                        <router-link
                                            :to="{
                                                name: 'HackathonApproveIdea',
                                                params: {
                                                    hackathon_id: hackathon_id,
                                                    idea_id: row.id,
                                                },
                                            }"
                                        >
                                            {{ row.name }}
                                        </router-link>
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="Submitter" min-width="240">
                                <template v-slot="{ row }">
                                    <a :href="'mailto:' + row.creator.email"
                                        >{{ row.creator.first_name }}
                                        {{ row.creator.last_name }}</a
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column min-width="90px" label="Status">
                                <div slot-scope="{ row }" class="table-actions">
                                    <!-- <el-tooltip
                                        content=""
                                        placement="top"
                                    > -->
                                    <b-badge
                                        pill
                                        :variant="
                                            row.approval.state.name ===
                                            'APPROVED'
                                                ? 'info'
                                                : 'primary'
                                        "
                                        >{{
                                            row.approval.state.display_name
                                        }}</b-badge
                                    >
                                    <!-- </el-tooltip> -->
                                </div>
                            </el-table-column>
                        </el-table>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// Modules
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
    Select,
    Option,
} from "element-ui";
import Badge from "@/components/Badge";

import {
    Hackathon,
    processHackathonRelayData,
} from "@/ORM/Hackathon/Hackathon.js";
import { processIdeaRelayData, Idea } from "@/ORM/Hackathon/Idea.js";

// Queries
import { GET_HACKATHON_APPROVAL_IDEAS } from "@/graphql/queries";

// Mutations

export default {
    name: "HackthonCrudSubmissionsTable",
    components: {
        Badge,
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {
        hackathon_id: {
            type: String,
            description: "The Id of the hackathon being viewed.",
            default: null,
        },
    },
    apollo: {
        get_hackathon_approval_ideas: {
            query: GET_HACKATHON_APPROVAL_IDEAS,
            result(res) {
                this.table_data = processIdeaRelayData(res);
                this.hackathon = processHackathonRelayData(res);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_approval_ideas = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_approval_ideas: null,
            },
            table_data: [], // All the criteria table data is in here.
            hackathon: new Hackathon(),
        };
    },
    methods: {
        manage_get_hackathon_approval_ideas() {
            if (this.hackathon_id) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_approval_ideas
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_approval_ideas
                );
            }
        },
    },
    watch: {
        hackathon_id() {
            this.manage_get_hackathon_approval_ideas();
        },
    },
    mounted() {
        this.manage_get_hackathon_approval_ideas();
        if (!this.$apollo.queries.get_hackathon_approval_ideas.skip) {
            this.$apollo.queries.get_hackathon_approval_ideas.refetch();
        }
    },
    created() {
        // setting this queries fetch policy

        this.manage_get_hackathon_approval_ideas();

        graph_utils.set_fetch_network_only(
            this.$apollo.queries.get_hackathon_approval_ideas
        );
    },
};
</script>

<style></style>
